import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

/* ====================================================== */
/*                       Components                       */
/* ====================================================== */

import Header from '../components/header/header'
import Footer from '../components/footer/footer'

/* ====================================================== */
/*                         Styles                         */
/* ====================================================== */

import styles from './layout.module.scss'
import './app.scss'
import '../identity/fonts.scss'

/* ====================================================== */
/*                    Implementation                      */
/* ====================================================== */

const Layout = ({ children, location, headerFullBackground }) => {
	// Accessibility
	if (process.env.NODE_ENV !== 'production') {
		var axe = require('react-axe')
		axe(React, ReactDOM, 1000)
	}

	const { site, logoImage, categories } = useStaticQuery(graphql`
		query LayoutQuery {
			site {
				siteMetadata {
					title
				}
			}
			logoImage: file(absolutePath: { regex: "//edpuzzle-icon.png/" }) {
				childImageSharp {
					fixed(width: 26) {
						...GatsbyImageSharpFixed
					}
				}
			}
			categories: allContentfulCategory(sort: { fields: [orderNumber] }) {
				edges {
					node {
						title
						slug
					}
				}
			}
		}
	`)

	return (
		<div className={styles.Page}>
			<Header
				siteTitle={site.siteMetadata.title}
				logoImage={logoImage}
				currentPathName={location.pathName}
				className={styles.Header}
				categories={categories.edges}
				headerFullBackground={headerFullBackground}
			/>
			<div className={styles.Wrapper}>
				<div className={styles.Body}>
					<main>{children}</main>
				</div>
				<Footer className={styles.Footer} />
			</div>
		</div>
	)
}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
	headerFullBackground: PropTypes.bool
}

Layout.defaultProps = {
	headerFullBackground: false
}

export default Layout
