// If you want to edit the basic routes, please edit also the file `gatsby-node.js`

const routes = {
	base() {
		return 'https://blog.edpuzzle.com'
	},
	index() {
		return '/'
	},
	notFound() {
		return '/404/'
	},
	legalDisclaimer() {
		return '/legal/'
	},
	category({ slug, base = false } = {}) {
		if (base) return `${routes.base()}/${slug}/`
		return `/${slug}/`
	},
	post({ categorySlug, postSlug, base = false } = {}) {
		if (base) return `${routes.base()}/${categorySlug}/${postSlug}/`
		return `/${categorySlug}/${postSlug}/`
	},

	// Edpuzzle
	// -------
	edpuzzle() {
		return 'https://edpuzzle.com'
	},
	pricing({ plan } = {}) {
		if (plan) {
			return `${routes.edpuzzle()}/pricing?plan=${plan}`
		}
		return `${routes.edpuzzle()}/pricing`
	},
	about() {
		return `${routes.edpuzzle()}/about`
	},
	jobs() {
		return `${routes.edpuzzle()}/jobs`
	},
	privacyCenter() {
		return `${routes.edpuzzle()}/privacycenter`
	},
	termsOfService({ section } = {}) {
		if (section) return `${routes.edpuzzle()}/terms#${section}`
		return `${routes.edpuzzle()}/terms`
	},
	privacyPolicy({ section } = {}) {
		if (section) return `${routes.edpuzzle()}/privacy#${section}`
		return `${routes.edpuzzle()}/privacy`
	},
	spreadTheWord() {
		return `${routes.edpuzzle()}/spread-the-word`
	},
	resourcesSchoolsDistricts() {
		return `${routes.edpuzzle()}/schools-districts`
	},

	// Support
	// -------
	supportMailTo() {
		return 'mailto:support@edpuzzle.com'
	},

	// Social Media
	// -------

	twitter(accountName) {
		return `https://twitter.com/${accountName}`
	},
	facebook(accountName) {
		return `https://facebook.com/${accountName}`
	},
	instagram(accountName) {
		return `https://instagram.com/${accountName}`
	},
	linkedinEdpuzzle() {
		return 'https://www.linkedin.com/company/edpuzzle-inc-/'
	},

	// Help Center
	// -------

	HCgettingStarted() {
		return 'https://support.edpuzzle.com/hc/sections/360001671011-Getting-Started'
	},

	// Marketing
	// ---------

	certifications() {
		return 'https://learn.edpuzzle.com/onlinepd'
	},

	// Mailchimp
	mailchimpLegal() {
		return 'https://mailchimp.com/legal/'
	}
}

export default routes
