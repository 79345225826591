import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { Link } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-gtag'

/* ====================================================== */
/*                         Styles                         */
/* ====================================================== */

import styles from './button.module.scss'

/* ====================================================== */
/*                    Implementation                      */
/* ====================================================== */

const AS = {
	BUTTON: 'button',
	LINK: 'link'
}

const Button = React.forwardRef((props, ref) => <BaseButton {...props} ref={ref} />)

Button.propTypes = {
	className: PropTypes.string,
	large: PropTypes.bool,
	secondary: PropTypes.bool,
	accent: PropTypes.bool,
	disabled: PropTypes.bool,
	block: PropTypes.bool,
	children: PropTypes.node.isRequired,
	as: PropTypes.string,
	onClick: PropTypes.func,
	type: PropTypes.string,
	to: PropTypes.string,
	external: PropTypes.bool,
	trackAnalytics: PropTypes.bool,
	target: PropTypes.string,
	rel: PropTypes.string
}

Button.defaultProps = {
	className: '',
	large: false,
	secondary: false,
	accent: false,
	disabled: false,
	block: false,
	as: AS.BUTTON,
	onClick: () => {},
	type: 'button',
	to: '',
	external: false,
	trackAnalytics: false,
	target: '_blank',
	rel: 'noopener noreferrer'
}

export default Button

/* ====================================================== */
/*                         Helpers                        */
/* ====================================================== */

const BaseButton = React.forwardRef(
	(
		{
			className,
			large,
			secondary,
			accent,
			disabled,
			block,
			children,
			as,
			onClick,
			type,
			to,
			external,
			trackAnalytics,
			target,
			rel,
			...props
		},
		ref
	) => {
		const classes = classnames(className, styles.Button, {
			// size
			[styles.Medium]: !large,
			[styles.Large]: large,
			// style
			[styles.Primary]: !secondary && !accent,
			[styles.Secondary]: secondary,
			[styles.Accent]: accent,
			// mode
			[styles.Block]: block
		})

		switch (as) {
			case AS.BUTTON:
				return (
					<button
						className={classes}
						disabled={disabled}
						ref={ref}
						type={type}
						onClick={disabled ? null : onClick}
						{...props}
					>
						{children}
					</button>
				)
			case AS.LINK:
				if (external) {
					if (trackAnalytics) {
						return (
							<OutboundLink className={classes} ref={ref} href={to} target={target} rel={rel} {...props}>
								{children}
							</OutboundLink>
						)
					}
					return (
						<a className={classes} href={to} target={target} ref={ref} rel={rel} {...props}>
							{children}
						</a>
					)
				}
				return (
					<Link className={classes} to={to} onClick={onClick} ref={ref} {...props}>
						{children}
					</Link>
				)
			default:
				break
		}
	}
)
