import React from 'react'
import PropTypes from 'prop-types'
import routes from '../../../routes'
import classnames from 'classnames'
import { COLORS } from '../../identity/colors'
/* ====================================================== */
/*                         Styles                         */
/* ====================================================== */
import styles from './footer.module.scss'
/* ====================================================== */
/*                       Components                       */
/* ====================================================== */
import Text from '../../elements/text/text'
import TextLink from '../../elements/text_link/text_link'
import Button from '../../elements/button/button'
/* ====================================================== */
/*                    Implementation                      */
/* ====================================================== */
const PageFooter = ({ className }) => (
	<footer className={classnames(className, styles.Footer)}>
		<div className={styles.Row}>
			<div className={styles.LinksContainer}>
				<div className={styles.LinksSubContainer}>
					<Resources />
					<Community />
				</div>
				<div className={styles.LinksSubContainer}>
					<Company />
					<Features />
				</div>
			</div>
			<TryEdpuzzle />
		</div>
		<div className={styles.Row}>
			<Copyright />
			<Legal />
		</div>
	</footer>
)
PageFooter.propTypes = {
	className: PropTypes.string
}
PageFooter.defaultProps = {
	className: ''
}
const Resources = () => (
	<nav aria-label="resources" className={styles.LinksColumn}>
		<Text size="18" chunky color={COLORS.SNOW}>
			Resources
		</Text>
		<ul className={styles.LinksList}>
			<li>
				<TextLink
					size="14"
					negative
					external
					rel="external noopener"
					trackAnalytics
					to={routes.HCgettingStarted()}
				>
					Getting Started
				</TextLink>
			</li>
			<li>
				<TextLink size="14" negative external rel="external" trackAnalytics to={routes.spreadTheWord()}>
					Spread The Word
				</TextLink>
			</li>
			<li>
				<TextLink
					size="14"
					negative
					external
					rel="external"
					trackAnalytics
					to={routes.resourcesSchoolsDistricts()}
				>
					Schools & Districts
				</TextLink>
			</li>
			<li>
				<TextLink
					size="14"
					negative
					external
					rel="external noopener"
					trackAnalytics
					to={routes.certifications()}
				>
					Certifications
				</TextLink>
			</li>
		</ul>
	</nav>
)
const Community = () => (
	<nav aria-label="community" className={styles.LinksColumn}>
		<Text size="18" chunky color={COLORS.SNOW}>
			Community
		</Text>
		<ul className={styles.LinksList}>
			<li>
				<TextLink
					size="14"
					negative
					external
					rel="external nofollow noopener noreferrer"
					trackAnalytics
					to={routes.twitter('edpuzzle')}
				>
					Twitter
				</TextLink>
			</li>
			<li>
				<TextLink
					size="14"
					negative
					external
					rel="external nofollow noopener noreferrer"
					trackAnalytics
					to={routes.facebook('edpuzzle')}
				>
					Facebook
				</TextLink>
			</li>
			<li>
				<TextLink
					size="14"
					negative
					external
					rel="external nofollow noopener noreferrer"
					trackAnalytics
					to={routes.instagram('edpuzzle')}
				>
					Instagram
				</TextLink>
			</li>
			<li>
				<TextLink
					size="14"
					negative
					external
					rel="external nofollow noopener noreferrer"
					trackAnalytics
					to={routes.linkedinEdpuzzle()}
				>
					LinkedIn
				</TextLink>
			</li>
		</ul>
	</nav>
)
const Company = () => (
	<nav aria-label="company" className={styles.LinksColumn}>
		<Text size="18" chunky color={COLORS.SNOW}>
			Company
		</Text>
		<ul className={styles.LinksList}>
			<li>
				<TextLink size="14" negative external rel="external" trackAnalytics to={routes.about()}>
					About us
				</TextLink>
			</li>
			<li>
				<TextLink
					size="14"
					negative
					external
					rel="external nofollow noreferrer noopener"
					trackAnalytics
					to={routes.jobs()}
				>
					Jobs
				</TextLink>
			</li>
			<li>
				<TextLink
					size="14"
					negative
					external
					rel="external noopener noreferrer"
					trackAnalytics
					to={routes.supportMailTo()}
				>
					Contact
				</TextLink>
			</li>
			<li>
				<TextLink size="14" negative external rel="external" trackAnalytics to={routes.privacyCenter()}>
					Privacy & Security
				</TextLink>
			</li>
		</ul>
	</nav>
)
const Features = () => (
	<nav aria-label="features" className={styles.LinksColumn}>
		<Text size="18" chunky color={COLORS.SNOW}>
			Features
		</Text>
		<ul className={styles.LinksList}>
			<li>
				<TextLink size="14" negative external rel="external" trackAnalytics to={routes.pricing()}>
					Check our plans
				</TextLink>
			</li>
		</ul>
	</nav>
)
const TryEdpuzzle = () => (
	<span>
		<Button
			as="link"
			accent
			large
			external
			rel="external"
			trackAnalytics
			to={routes.edpuzzle()}
			className={styles.TryEdpuzzleButton}
		>
			Try Edpuzzle
		</Button>
	</span>
)
const Copyright = () => {
	const date = new Date()
	return (
		<Text size="14" color={COLORS.SNOW}>
			&copy; {date.getFullYear()} EDpuzzle Inc.
		</Text>
	)
}
const Legal = () => (
	<nav aria-label="legal" className={styles.LegalContainer}>
		<TextLink size="14" negative to={routes.legalDisclaimer()}>
			Legal Disclaimer
		</TextLink>
		<Text size="14" color={COLORS.SNOW} className={styles.LegalBar}>{` | `}</Text>
		<TextLink size="14" negative external rel="external" trackAnalytics to={routes.termsOfService()}>
			Terms of Service
		</TextLink>
		<Text size="14" color={COLORS.SNOW} className={styles.LegalBar}>{` | `}</Text>
		<TextLink size="14" negative external rel="external" trackAnalytics to={routes.privacyPolicy()}>
			Privacy Policy
		</TextLink>
	</nav>
)
/* ====================================================== */
/*                       Public API                       */
/* ====================================================== */
export default PageFooter
