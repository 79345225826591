import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

const Image = ({ fluid, fixed, alt, wrapperClassName, objectFit, ...props }) => {
	let imgStyle = {}
	if (objectFit !== 'cover') {
		imgStyle = {
			objectFit: objectFit
		}
	}
	if (fluid) {
		return (
			<Img
				fluid={fluid}
				alt={alt}
				className={wrapperClassName}
				objectFit={objectFit}
				imgStyle={imgStyle}
				{...props}
			/>
		)
	}

	if (fixed) {
		return (
			<Img
				fixed={fixed}
				alt={alt}
				className={wrapperClassName}
				objectFit={objectFit}
				imgStyle={imgStyle}
				{...props}
			/>
		)
	}
}

Image.propTypes = {
	fluid: PropTypes.object,
	fixed: PropTypes.object,
	wrapperClassName: PropTypes.string,
	objectFit: PropTypes.string,
	alt: PropTypes.string.isRequired
}

Image.defaultProps = {
	wrapperClassName: '',
	objectFit: 'cover'
}

export default Image
