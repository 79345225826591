import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'
import routes from '../../../routes'

/* ====================================================== */
/*                         Styles                         */
/* ====================================================== */

import styles from './header.module.scss'

/* ====================================================== */
/*                       Components                       */
/* ====================================================== */

import { Link } from 'gatsby'
import BurguerIcon from '../../helpers/icons/burguer/burguer_icon'
import CloseIcon from '../../helpers/icons/close/close_icon'
import TextLink from '../../elements/text_link/text_link'
import Button from '../../elements/button/button'
import Image from '../../elements/image/image'
import { Wrapper, MenuOpener, Menu, MenuItem } from '../dropdown_menu/dropdown_menu'

/* ====================================================== */
/*                    Implementation                      */
/* ====================================================== */

class Header extends React.Component {
	static propTypes = {
		logoImage: PropTypes.object.isRequired,
		categories: PropTypes.arrayOf(PropTypes.object).isRequired,
		className: PropTypes.string,
		headerFullBackground: PropTypes.bool
	}

	static defaultProps = {
		className: '',
		headerFullBackground: false
	}

	constructor(props) {
		super(props)

		this.state = {
			isOpen: false,
			onTop: true
		}
	}

	handleScroll = () => {
		const onTop = window.scrollY < 100
		this.setState({
			onTop: onTop
		})
	}

	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll)
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll)
	}

	renderNavigationForSmallScreens = () => (
		<Wrapper
			tag="nav"
			aria-label="main navigation"
			className={styles.HamburguerMenuWrapper}
			onMenuToggle={({ isOpen }) => {
				this.setState({ isOpen })
			}}
		>
			{!this.state.isOpen ? (
				<Button as="link" external rel="external" trackAnalytics to={routes.edpuzzle()}>
					Try Edpuzzle
				</Button>
			) : null}
			<MenuOpener
				renderOpener={({ getButtonProps }) => (
					<button
						id="navigation-toggle"
						className={classnames(styles.NavigationOpener, {
							[styles.isOpen]: this.state.isOpen,
							[styles.isClose]: !this.state.isOpen
						})}
						aria-label={this.state.isOpen ? 'Close navigation menu' : 'Open navigation menu'}
						aria-controls="navigation-menu"
						{...getButtonProps()}
					>
						<CloseIcon alt="Close navigation menu" aria-hidden="true" className={styles.IconToClose} />
						<BurguerIcon alt="Open navigation menu" aria-hidden="true" className={styles.IconToOpen} />
					</button>
				)}
			/>
			<Menu id="navigation-menu" role="menu" className={styles.Menu} aria-labelledby="navigation-toggle">
				<ul className={styles.List}>
					{this.props.categories.map(({ node }, i) => (
						<li key={i}>
							<MenuItem
								renderMenuItem={({ menuItemProps }) => (
									<TextLink as="link" size="24" to={routes.category({ slug: node.slug })} {...menuItemProps}>
										{node.title}
									</TextLink>
								)}
							/>
						</li>
					))}
					<li>
						<MenuItem
							renderMenuItem={({ menuItemProps }) => (
								<Button as="link" external to={routes.edpuzzle()} {...menuItemProps}>
									Try Edpuzzle
								</Button>
							)}
						/>
					</li>
				</ul>
			</Menu>
		</Wrapper>
	)

	renderNavigationForBigScreens = () => (
		<nav className={styles.NavMenuWrapper} aria-label="main navigation">
			<ul className={styles.NavMenuList}>
				{this.props.categories.map(({ node }, i) => (
					<li key={i}>
						<TextLink as="link" size="16" chubby to={routes.category({ slug: node.slug })}>
							{node.title}
						</TextLink>
					</li>
				))}
				<li>
					<Button as="link" external trackAnalytics to={routes.edpuzzle()}>
						Try Edpuzzle
					</Button>
				</li>
			</ul>
		</nav>
	)

	render() {
		return (
			<header
				className={classnames(this.props.className, styles.Header, {
					[styles.MenuOpen]: this.state.isOpen,
					[styles.FullBackground]: this.props.headerFullBackground,
					[styles.onTop]: this.state.onTop
				})}
			>
				<div
					className={classnames(styles.LeftSide, {
						[styles.MenuOpen]: this.state.isOpen
					})}
				>
					<Link to={routes.index()} className={styles.LogoLink}>
						<Image
							fixed={this.props.logoImage.childImageSharp.fixed}
							alt="Edpuzzle Blog"
							objectFit="fill"
							wrapperClassName={styles.LogoImage}
						/>
					</Link>
				</div>

				<div className={styles.RightSide}>
					{this.renderNavigationForSmallScreens()}
					{this.renderNavigationForBigScreens()}
				</div>
			</header>
		)
	}
}

export default Header
