import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
/* ====================================================== */
/*                    Implementation                      */
/* ====================================================== */
const SEO = ({ description, lang, meta, keywords, title, url, image, imageAlt, author }) => {
	const { site } = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					title
					description
					keywords
					author
					twitter_account
					fb_appid
					url
					image
				}
			}
		}
	`)
	const metaDescription = description || site.siteMetadata.description
	const metaKeywords = keywords && keywords.length > 0 ? keywords : site.siteMetadata.keywords
	const metaURL = url || site.siteMetadata.url
	const metaImage = image ? `https:${image}` : `${site.siteMetadata.url}${site.siteMetadata.image}`
	const metaImageAlt = imageAlt
	const metaType = image ? 'article' : 'website'
	const metaTitle = title || site.siteMetadata.title
	const metaAuthor = author || site.siteMetadata.author
	return (
		<Helmet
			htmlAttributes={{
				lang
			}}
			title={title || 'Home'}
			titleTemplate={`%s | ${site.siteMetadata.title}`}
			meta={[
				{
					name: 'description',
					content: metaDescription
				},
				{
					name: 'keywords',
					content: metaKeywords
				},
				{
					name: 'author',
					content: metaAuthor
				},
				{
					name: 'article:author',
					content: metaAuthor
				},
				{
					name: 'article:publisher',
					content: site.siteMetadata.author
				},
				// facebook
				{
					property: 'og:title',
					content: metaTitle
				},
				{
					property: 'og:description',
					content: metaDescription
				},
				{
					property: 'og:type',
					content: metaType
				},
				{
					property: 'og:url',
					content: metaURL
				},
				{
					property: 'og:site_name',
					content: site.siteMetadata.title
				},
				{
					property: 'og:image',
					content: metaImage
				},
				{
					property: 'og:image:alt',
					content: metaImageAlt
				},
				{
					property: 'fb:app_id',
					content: site.siteMetadata.fb_appid
				},
				// twitter
				{
					name: 'twitter:card',
					content: image ? 'summary_large_image' : 'summary'
				},
				{
					name: 'twitter:creator',
					content: site.siteMetadata.twitter_account
				},
				{
					name: 'twitter:site',
					content: site.siteMetadata.twitter_account
				},
				{
					name: 'twitter:title',
					content: metaTitle
				},
				{
					name: 'twitter:description',
					content: metaDescription
				},
				{
					name: 'twitter:image',
					content: metaImage
				},
				{
					name: 'twitter:image:alt',
					content: metaImageAlt
				}
			].concat(meta)}
		/>
	)
}
SEO.defaultProps = {
	description: '',
	lang: 'en',
	meta: [],
	keywords: [],
	title: '',
	url: '',
	image: '',
	imageAlt: 'Edpuzzle logo',
	author: ''
}
SEO.propTypes = {
	description: PropTypes.string,
	lang: PropTypes.string,
	meta: PropTypes.arrayOf(PropTypes.object),
	keywords: PropTypes.arrayOf(PropTypes.string),
	title: PropTypes.string,
	url: PropTypes.string,
	image: PropTypes.string,
	imageAlt: PropTypes.string,
	author: PropTypes.string
}
export default SEO
