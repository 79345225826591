import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Link } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-gtag'

/* ====================================================== */
/*                       Components                       */
/* ====================================================== */

import detectColor, { COLORS } from '../../identity/colors'

/* ====================================================== */
/*                         Styles                         */
/* ====================================================== */

import styles from './text_link.module.scss'

/* ====================================================== */
/*                     Export variables                   */
/* ====================================================== */

const AS = {
	BUTTON: 'button',
	LINK: 'link'
}

export const SIZE = {
	T56: '56',
	T36: '36',
	T28: '28',
	T24: '24',
	T18: '18',
	T16: '16',
	T14: '14'
}

/* ====================================================== */
/*                    Implementation                      */
/* ====================================================== */

const TextLink = React.forwardRef((props, ref) => <BaseTextLink {...props} ref={ref} />)

TextLink.propTypes = {
	children: PropTypes.node.isRequired,
	as: PropTypes.string,
	// style
	size: PropTypes.string,
	chubby: PropTypes.bool,
	chunky: PropTypes.bool,
	negative: PropTypes.bool,
	testament: PropTypes.bool,
	tag: PropTypes.bool,
	block: PropTypes.bool,
	color: PropTypes.string,
	className: PropTypes.string,
	// link props
	to: PropTypes.string,
	external: PropTypes.bool,
	trackAnalytics: PropTypes.bool,
	target: PropTypes.string,
	rel: PropTypes.string,
	// button props
	onClick: PropTypes.func,
	type: PropTypes.string
}

TextLink.defaultProps = {
	as: AS.LINK,
	// style
	size: SIZE.T16,
	chubby: false,
	chunky: false,
	negative: false,
	testament: false,
	tag: false,
	block: false,
	color: COLORS.INHERIT,
	className: '',
	// link props
	to: '',
	external: false,
	trackAnalytics: false,
	target: '_blank',
	rel: 'noopener noreferrer',
	// button props
	onClick: () => {},
	type: 'button'
}

export default TextLink

/* ====================================================== */
/*                         Helpers                        */
/* ====================================================== */

const BaseTextLink = React.forwardRef(
	(
		{
			children,
			as,
			size,
			chubby,
			chunky,
			negative,
			testament,
			tag,
			block,
			color,
			className,
			to,
			external,
			trackAnalytics,
			target,
			rel,
			onClick,
			type,
			...props
		},
		ref
	) => {
		const classes = classnames(className, styles.TextLink, styles[`Color${detectColor(color)}`], {
			// sizes
			[styles.T56]: size === SIZE.T56,
			[styles.T36]: size === SIZE.T36,
			[styles.T28]: size === SIZE.T28,
			[styles.T24]: size === SIZE.T24,
			[styles.T18]: size === SIZE.T18,
			[styles.T16]: size === SIZE.T16,
			[styles.T14]: size === SIZE.T14,
			// Weigth
			[styles.Chubby]: chubby,
			[styles.Chunky]: chunky,
			// style
			[styles.Regular]: !negative && !testament && !tag,
			[styles.Negative]: negative,
			[styles.Testament]: testament,
			[styles.Tag]: tag,
			// mode
			[styles.Block]: block
		})

		switch (as) {
			case AS.LINK:
				if (external) {
					if (trackAnalytics) {
						return (
							<OutboundLink className={classes} ref={ref} href={to} target={target} rel={rel} {...props}>
								{children}
							</OutboundLink>
						)
					}
					return (
						<a className={classes} ref={ref} href={to} target={target} rel={rel} {...props}>
							{children}
						</a>
					)
				}
				return (
					<Link className={classes} ref={ref} to={to} onClick={onClick} {...props}>
						{children}
					</Link>
				)
			case AS.BUTTON:
				return (
					<button className={classes} ref={ref} type={type} onClick={onClick} {...props}>
						{children}
					</button>
				)
			default:
				break
		}
	}
)
