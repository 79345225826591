/* ====================================================== */
/*                     Public API                         */
/* ====================================================== */

export const COLORS = {
	INHERIT: 'inherit',

	VULCAN: 'vulcan', // #171717
	GRAPHITE: 'graphite', // #333333
	ASHES: 'ashes', // #54575a
	GREY: 'grey', // #939596
	SMOG: 'smog', // #b8baba
	SILVER: 'silver', // #e9eaea
	VAPOR: 'vapor', // #fafafa
	SNOW: 'snow', // #ffffff

	NAVY: 'navy', // #0055cb
	AZURE: 'azure', // #0065ff
	DENIM: 'denim', // #358cff
	BABYBLUE: 'babyBlue', // #C8E6FF

	PINEAPPLE: 'pineapple', // #FFDF61
	JUNGLE: 'jungle', // #0C9C7B
	CARROT: 'carrot', // #FA6700
	PUMPKIN: 'pumpkin', // #FF8733
	CRIMSON: 'crimson' // #DC143C
}

const colorValues = Object.keys(COLORS).map(key => COLORS[key])

const detectColor = color => {
	if (colorValues.indexOf(color) === -1) return ''
	return color.charAt(0).toUpperCase() + color.slice(1)
}

export default detectColor
