import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

/* ====================================================== */
/*                       Components                       */
/* ====================================================== */

import detectColor, { COLORS } from '../../identity/colors'

/* ====================================================== */
/*                         Styles                         */
/* ====================================================== */

import styles from './text.module.scss'

/* ====================================================== */
/*                    Implementation                      */
/* ====================================================== */

export const HTML_ELEMENT = {
	H1: 'h1',
	H2: 'h2',
	H3: 'h3',
	H4: 'h4',
	H5: 'h5',
	H6: 'h6',
	P: 'p',
	SPAN: 'span',
	CODE: 'code',
	LABEL: 'label',
	LEGEND: 'legend',
	LI: 'li'
}

export const SIZE = {
	T56: '56',
	T36: '36',
	T28: '28',
	T24: '24',
	T18: '18',
	T16: '16',
	T14: '14',
	T12: '12',
	T10: '10'
}

const Text = ({ as, size, block, chubby, chunky, code, testament, phone, color, className, ...props }) => {
	const classes = classnames(className, styles.Text, styles[`Color${detectColor(color)}`], {
		[styles.Block]: block,
		[styles.Chubby]: chubby,
		[styles.Chunky]: chunky,
		[styles.Light]: !chunky && !chubby,
		[styles.Code]: code,
		[styles.Testament]: testament,
		[styles.Phone]: phone,
		// sizes
		[styles.T56]: size === SIZE.T56,
		[styles.T36]: size === SIZE.T36,
		[styles.T28]: size === SIZE.T28,
		[styles.T24]: size === SIZE.T24,
		[styles.T18]: size === SIZE.T18,
		[styles.T16]: size === SIZE.T16,
		[styles.T14]: size === SIZE.T14,
		[styles.T12]: size === SIZE.T12,
		[styles.T10]: size === SIZE.T10
	})
	switch (as) {
		case HTML_ELEMENT.H1:
			return <h1 className={classes} {...props} />
		case HTML_ELEMENT.H2:
			return <h2 className={classes} {...props} />
		case HTML_ELEMENT.H3:
			return <h3 className={classes} {...props} />
		case HTML_ELEMENT.H4:
			return <h4 className={classes} {...props} />
		case HTML_ELEMENT.H5:
			return <h5 className={classes} {...props} />
		case HTML_ELEMENT.H6:
			return <h6 className={classes} {...props} />
		case HTML_ELEMENT.SPAN:
			return <span className={classes} {...props} />
		case HTML_ELEMENT.CODE:
			return <code className={classes} {...props} />
		case HTML_ELEMENT.LABEL:
			return <label className={classes} {...props} />
		case HTML_ELEMENT.LEGEND:
			return <legend className={classes} {...props} />
		case HTML_ELEMENT.LI:
			return <li className={classes} {...props} />
		default:
			return <p className={classes} {...props} />
	}
}

Text.propTypes = {
	as: PropTypes.oneOf(Object.values(HTML_ELEMENT)),
	size: PropTypes.oneOf(Object.values(SIZE)),
	block: PropTypes.bool,
	chubby: PropTypes.bool,
	chunky: PropTypes.bool,
	code: PropTypes.bool,
	testament: PropTypes.bool,
	phone: PropTypes.bool,
	color: PropTypes.oneOf(Object.values(COLORS)),
	className: PropTypes.string
}

Text.defaultProps = {
	as: HTML_ELEMENT.P,
	size: SIZE.T18,
	block: false,
	chubby: false,
	chunky: false,
	code: false,
	testament: false,
	phone: false,
	color: COLORS.INHERIT,
	className: ''
}

export default Text
