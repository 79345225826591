import React from 'react'
import PropTypes from 'prop-types'

const CloseIcon = props => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="16"
		height="16"
		viewBox="0 0 16 16"
		aria-labelledby="close-icon"
		role="img"
		{...props}
	>
		<title id="close-icon">{props.alt}</title>
		<path
			fill="#4C5359"
			fillRule="nonzero"
			d="M9.227 8l6.603-6.603a.578.578 0 0 0 0-.818l-.409-.41a.578.578 0 0 0-.818 0L8 6.774 1.397.174a.578.578 0 0 0-.818 0l-.41.41a.578.578 0 0 0 0 .818L6.774 8 .174 14.603a.578.578 0 0 0 0 .818l.41.41a.578.578 0 0 0 .818 0L8 9.226l6.603 6.603a.578.578 0 0 0 .818 0l.41-.409a.578.578 0 0 0 0-.818L9.226 8z"
		/>
	</svg>
)

CloseIcon.propTypes = {
	alt: PropTypes.string.isRequired
}

export default CloseIcon
