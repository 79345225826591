import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import createTapListener from 'teeny-tap'

import createManager from './logic/createManager'
import specialAssign from './logic/specialAssign'
import externalStateControl from './logic/externalStateControl'

// FROM: https://github.com/davidtheclark/react-aria-menubutton/blob/master/
// Adjusted to be able to render other components

/* ====================================================== */
/*                         WRAPPER                        */
/* ====================================================== */

const wrapperCheckedProps = {
	children: PropTypes.node.isRequired,
	onMenuToggle: PropTypes.func,
	onSelection: PropTypes.func,
	closeOnSelection: PropTypes.bool,
	closeOnBlur: PropTypes.bool,
	tag: PropTypes.string
}

class AriaMenuButtonWrapper extends React.Component {
	static propTypes = wrapperCheckedProps
	static defaultProps = { tag: 'div' }

	static childContextTypes = {
		ambManager: PropTypes.object
	}

	getChildContext() {
		return {
			ambManager: this.manager
		}
	}

	componentWillMount() {
		this.manager = createManager({
			onMenuToggle: this.props.onMenuToggle,
			onSelection: this.props.onSelection,
			closeOnSelection: this.props.closeOnSelection,
			closeOnBlur: this.props.closeOnBlur,
			id: this.props.id
		})
	}

	render() {
		const wrapperProps = {}
		specialAssign(wrapperProps, this.props, wrapperCheckedProps)
		return React.createElement(this.props.tag, wrapperProps, this.props.children)
	}
}

/* ====================================================== */
/*                         BUTTON                         */
/* ====================================================== */

class AriaMenuButton extends React.Component {
	static propTypes = {
		renderOpener: PropTypes.func.isRequired,
		disabled: PropTypes.bool
	}

	static contextTypes = {
		ambManager: PropTypes.object.isRequired
	}

	static defaultProps = {
		disabled: false
	}

	componentWillMount() {
		this.context.ambManager.button = this
	}

	componentWillUnmount() {
		this.context.ambManager.destroy()
	}

	handleKeyDown = event => {
		if (this.props.disabled) return

		const ambManager = this.context.ambManager

		switch (event.key) {
			case 'ArrowDown':
				event.preventDefault()
				if (!ambManager.isOpen) {
					ambManager.openMenu()
				} else {
					ambManager.focusItem(0)
				}
				break
			case 'Enter':
			case ' ':
				event.preventDefault()
				ambManager.toggleMenu()
				break
			case 'Escape':
				ambManager.handleMenuKey(event)
				break
			default:
				// (Potential) letter keys
				ambManager.handleButtonNonArrowKey(event)
		}
	}

	handleClick = () => {
		if (this.props.disabled) return
		this.context.ambManager.toggleMenu({}, { focusMenu: false })
	}

	render() {
		const props = this.props
		const ambManager = this.context.ambManager

		const buttonProps = {
			// "The menu button itself has a role of button."
			role: 'button',
			tabIndex: props.disabled ? '' : '0',
			// "The menu button has an aria-haspopup property, set to true."
			'aria-haspopup': true,
			'aria-expanded': ambManager.isOpen,
			'aria-disabled': props.disabled,
			onKeyDown: this.handleKeyDown,
			onClick: this.handleClick
		}

		if (ambManager.options.closeOnBlur) {
			buttonProps.onBlur = ambManager.handleBlur
		}

		return this.props.renderOpener({
			getButtonProps: () => buttonProps
		})
	}
}

/* ====================================================== */
/*                          MENU                          */
/* ====================================================== */

const menuCheckedProps = {
	children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
	tag: PropTypes.string
}

class AriaMenuButtonMenu extends React.Component {
	static propTypes = menuCheckedProps
	static defaultProps = { tag: 'div' }

	static contextTypes = {
		ambManager: PropTypes.object.isRequired
	}

	componentWillMount() {
		this.context.ambManager.menu = this
	}

	componentDidUpdate() {
		const ambManager = this.context.ambManager
		if (!ambManager.options.closeOnBlur) return
		if (ambManager.isOpen && !this.tapListener) {
			this.addTapListener()
		} else if (!ambManager.isOpen && this.tapListener) {
			this.tapListener.remove()
			delete this.tapListener
		}

		if (!ambManager.isOpen) {
			// Clear the ambManager's items, so they
			// can be reloaded next time this menu opens
			ambManager.clearItems()
		}
	}

	componentWillUnmount() {
		if (this.tapListener) this.tapListener.remove()
		this.context.ambManager.destroy()
	}

	addTapListener = () => {
		const el = ReactDOM.findDOMNode(this)
		if (!el) return
		const doc = el.ownerDocument
		if (!doc) return
		this.tapListener = createTapListener(doc.documentElement, this.handleTap)
	}

	handleTap = event => {
		if (ReactDOM.findDOMNode(this).contains(event.target)) return
		if (ReactDOM.findDOMNode(this.context.ambManager.button).contains(event.target)) return
		this.context.ambManager.closeMenu()
	}

	render() {
		const props = this.props
		const ambManager = this.context.ambManager

		const childrenToRender = (function () {
			if (typeof props.children === 'function') {
				return props.children({ isOpen: ambManager.isOpen })
			}
			if (ambManager.isOpen) return props.children
			return false
		})()

		if (!childrenToRender) return false

		const menuProps = {
			onKeyDown: ambManager.handleMenuKey,
			role: 'menu',
			tabIndex: -1
		}

		if (ambManager.options.closeOnBlur) {
			menuProps.onBlur = ambManager.handleBlur
		}

		specialAssign(menuProps, props, menuCheckedProps)

		return React.createElement(props.tag, menuProps, childrenToRender)
	}
}

/* ====================================================== */
/*                          ITEMS                         */
/* ====================================================== */

const menuItemCheckedProps = {
	renderMenuItem: PropTypes.func,
	children: PropTypes.node,
	tag: PropTypes.string,
	text: PropTypes.string,
	value: PropTypes.any
}

class AriaMenuButtonMenuItem extends React.Component {
	static propTypes = menuItemCheckedProps
	static defaultProps = { tag: 'div' }
	node = React.createRef()

	static contextTypes = {
		ambManager: PropTypes.object.isRequired
	}

	componentDidMount() {
		this.context.ambManager.addItem({
			node: this.node.current,
			text: this.props.text
		})
	}

	handleKeyDown = event => {
		if (event.key !== 'Enter' && event.key !== ' ') return
		if (this.props.tag === 'a' && this.props.href) return
		if (this.props.renderMenuItem) return
		event.preventDefault()
		this.selectItem(event)
	}

	selectItem = event => {
		if (this.props.renderMenuItem) return this.context.ambManager.handleSelection(event.target, event)
		// If there's no value, we'll send the child
		const value = typeof this.props.value !== 'undefined' ? this.props.value : this.props.children
		this.context.ambManager.handleSelection(value, event)
	}

	render() {
		const menuItemProps = {
			onClick: this.selectItem,
			onKeyDown: this.handleKeyDown,
			role: 'menuitem',
			tabIndex: '-1',
			ref: this.node
		}

		specialAssign(menuItemProps, this.props, menuItemCheckedProps)

		if (this.props.renderMenuItem) {
			return this.props.renderMenuItem({
				menuItemProps
			})
		}
		return React.createElement(this.props.tag, menuItemProps, this.props.children)
	}
}

/* ====================================================== */
/*                         EXPORTS                        */
/* ====================================================== */

const openMenu = externalStateControl.openMenu
const closeMenu = externalStateControl.closeMenu

export {
	AriaMenuButtonWrapper as Wrapper,
	AriaMenuButton as MenuOpener,
	AriaMenuButtonMenu as Menu,
	AriaMenuButtonMenuItem as MenuItem,
	openMenu,
	closeMenu
}
